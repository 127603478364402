<template>
  <div :class="blockClass" :style="{ backgroundColor: backgroundColor, color: fontColor, opacity: opacity }">
    {{ blockContent }}
  </div>
</template>

<script lang="ts">
import { defineComponent, computed } from 'vue';

export default defineComponent({
  name: 'TimeBlock',
  props: {
    blockContent: {
      type: String,
      required: true
    },
    blockClass: {
      type: String,
      default: "w-full mb-[190px] text-[12px] pl-[8px] font-[500] pt-[1px]"
    },
    backgroundColor: {
      type: String,
      default: "grey"
    },
    opacity: {
      type: Number,
      default: 1
    }
  },
  computed: {
    fontColor(): string {
      // Compute contrast color based on background color
      const hex = this.backgroundColor.replace('#', '');
      const r = parseInt(hex.substring(0, 2), 16);
      const g = parseInt(hex.substring(2, 4), 16);
      const b = parseInt(hex.substring(4, 6), 16);
      const brightness = (r * 299 + g * 587 + b * 114) / 1000;
      return brightness > 125 ? 'black' : 'white'; // Choose black or white based on background brightness
    }
  }
});
</script>
