<template>
  <div class="flex">
    <div v-if="isMenuOpen"  class="bg-gray-900  w-[290px] pt-[16px] h-full relative">
      <div @click="closeMenu" class="pb-[30px]">
        <RouterLink to=""><img src="../assets/images/left-arrow.png" alt="img" class="absolute right-[15px]">
        </RouterLink>
      </div>
      <div class="flex items-center justify-between pr-[20px]">
        <div class="flex items-center gap-[10px] pl-[30px] pr[14px]">
          <div class="bg-gray-100 bg-opacity-100 p-[5px]">
            <img src="../assets/images/check-img.png" alt="check-img" class="w-[24px] h-[24px]">
          </div>
          <div>
            <h3 class="text-[14px] text-white font-[400] mb-[5px]">Exercise</h3>
            <p class="text-[10px] text-white font-[400] line-h-[14px]">in 15 minutes</p>
          </div>
        </div>
        <div class="p-[8px]" style="background-color: rgba(42, 35, 35, 1);">
          <p class="text-[8px] text-white text-center">WORK-LIFE <br> BALANCE</p>
        </div>
      </div>
      <div class="flex items-center text-center mt-[30px] mb-[30px]">
        <div class="bg-black opacity-[0.5] w-[30px] h-[50px]">.</div>
        <div class="bg-gray-500 text-white w-[80px] h-[50px]">
          <h3 class="text-[12px] font-[700]">Toronto</h3>
          <p class="text-white text-[10px] hover:text-white">Today <br> 0HRS</p>
        </div>
        <div class="bg-black opacity-[0.5] w-[80px] h-[50px]">
          <h3 class="text-white text-[12px] font-[700]">New York</h3>
          <p class="text-white text-[10px]">Today <br> 0HRS</p>
        </div>
        <div class="bg-black opacity-[0.5] w-[80px] h-[50px]">
          <h3 class="text-white text-[12px] font-[700]">Paris</h3>
          <p class="text-white text-[10px]">Today <br> 6HRS</p>
        </div>
        <div class="bg-black opacity-[0.5] w-[80px] h-[50px]">
          <h3 class="text-white text-[12px] font-[700]">Sydeny</h3>
          <p class="text-white text-[10px]">Today <br> 16HRS</p>
        </div>
      </div>
      <div class="pl-[30px]">
        <div>
          <h3 class="text-white text-[14px] mb-[5px]">Yesterday</h3>
          <div class="mb-[10px] flex">
            <div class="bg-black w-[15px] h-[170px]">
              <div class="bg-purple-800 opacity-100 w-[15px] h-[10px] mt-[14px]"></div>
              <div class="bg-blue-800 opacity-[0.5] w-[15px] h-[10px] mt-[30px]"></div>
              <div class="bg-pink-500  w-[15px] h-[10px] mt-[14px]"></div>
              <div class="bg-red-500 opacity-[0.5] w-[15px] h-[25px] mt-[14px]"></div>
            </div>
            <div class="flex gap-[20px] ml-[10px]">
              <div>
                <div class="text-purple-800 opacity-100 text-[14px] font-[500] mt-[8px]">Breakfast</div>
                <div class="text-blue-800 opacity-[0.5] text-[14px] font-[500] mt-[20px]">Make Lunch</div>
                <div class="text-pink-800 text-[14px] font-[500] mt-[3px]">Duhr</div>
                <div class="text-red-500 opacity-[0.5] text-[14px] font-[500] mt-[5px]">Duhr</div>
              </div>
              <div>
                <div class="text-purple-800 opacity-100 text-[14px] font-[500] mt-[8px]">30min</div>
                <div class="text-blue-800 opacity-[0.5] text-[14px] font-[500] mt-[20px]">20min</div>
                <div class="text-pink-800 text-[14px] font-[500] mt-[3px]">15min</div>
                <div class="text-red-500 opacity-[0.5] text-[14px] font-[500] mt-[5px]">15min</div>
              </div>
            </div>
          </div>
        </div>
        <div>
          <h3 class="text-white text-[14px] mb-[5px]">Today</h3>
          <div class="mb-[10px] flex">
            <div class="bg-black w-[15px] h-[140px]">
              <div class="bg-purple-800 opacity-100 w-[15px] h-[10px] mt-[14px]"></div>
              <div class="bg-blue-800 opacity-[0.5] w-[15px] h-[10px] mt-[30px]"></div>
              <div class="bg-pink-500  w-[15px] h-[10px] mt-[14px]"></div>
            </div>
            <div class="flex gap-[20px] ml-[10px]">
              <div>
                <div class="text-purple-800 opacity-100 text-[14px] font-[500] mt-[8px]">Breakfast</div>
                <div class="text-blue-800 opacity-[0.5] text-[14px] font-[500] mt-[20px]">Make Lunch</div>
                <div class="text-pink-800 text-[14px] font-[500] mt-[3px]">Duhr</div>
              </div>
              <div>
                <div class="text-purple-800 opacity-100 text-[14px] font-[500] mt-[8px]">30min</div>
                <div class="text-blue-800 opacity-[0.5] text-[14px] font-[500] mt-[20px]">20min</div>
                <div class="text-pink-800 text-[14px] font-[500] mt-[3px]">15min</div>
              </div>
            </div>
          </div>
        </div>
        <div>
          <h3 class="text-white text-[14px] mb-[5px]">Tomorrow</h3>
          <div class="mb-[25px] flex">
            <div class="bg-black w-[15px] h-[140px]">
              <div class="bg-purple-800 opacity-100 w-[15px] h-[10px] mt-[14px]"></div>
              <div class="bg-blue-800 opacity-[0.5] w-[15px] h-[10px] mt-[30px]"></div>
              <div class="bg-pink-500  w-[15px] h-[10px] mt-[14px]"></div>
            </div>
            <div class="flex gap-[20px] ml-[10px]">
              <div>
                <div class="text-purple-800 opacity-100 text-[14px] font-[500] mt-[8px]">Breakfast</div>
                <div class="text-blue-800 opacity-[0.5] text-[14px] font-[500] mt-[20px]">Make Lunch</div>
                <div class="text-pink-800 text-[14px] font-[500] mt-[3px]">Duhr</div>
              </div>
              <div>
                <div class="text-purple-800 opacity-100 text-[14px] font-[500] mt-[8px]">30min</div>
                <div class="text-blue-800 opacity-[0.5] text-[14px] font-[500] mt-[20px]">20min</div>
                <div class="text-pink-800 text-[14px] font-[500] mt-[3px]">15min</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="pl-[30px]">
        <div class="bg-gray-800 w-full h-[200px] relative">
          <div class="text-white pl-[10px] pt-[5px]">Daily Breakfast</div>
          <div class="text-white pl-[10px] pt-[5px]">6hrs</div>
          <div class="flex pl-[20px] gap-[12px] pb-[1px] items-end">
            <div>
              <div class="bg-red-500 opacity-[0.5] w-[10px] h-[25px] ml-[7px]"></div>
              <div class="bg-purple-800 opacity-100 w-[10px] h-[16px] ml-[7px]"></div>
              <div class="bg-blue-800 opacity-[0.5] w-[10px] h-[22px] ml-[7px]"></div>
              <div class="bg-pink-500  w-[10px] h-[24px] ml-[7px]"></div>
              <div class="text-white text-[12px] text-center">sat</div>
            </div>
            <div>
              <div class="bg-red-500 opacity-[0.5] w-[10px] h-[25px] ml-[7px]"></div>
              <div class="bg-purple-800 opacity-100 w-[10px] h-[16px] ml-[7px]"></div>
              <div class="bg-blue-800 opacity-[0.5] w-[10px] h-[22px] ml-[7px]"></div>
              <div class="bg-pink-500  w-[10px] h-[24px] ml-[7px]"></div>
              <div class="text-white text-[12px] text-center">Sun</div>
            </div>
            <div>
              <div class="bg-red-500 opacity-[0.5] w-[10px] h-[25px] ml-[7px]"></div>
              <div class="bg-purple-800 opacity-100 w-[10px] h-[16px] ml-[7px]"></div>
              <div class="bg-blue-800 opacity-[0.5] w-[10px] h-[22px] ml-[7px]"></div>
              <div class="bg-pink-500  w-[10px] h-[24px] ml-[7px]"></div>
              <div class="text-white text-[12px] text-center">Mon</div>
            </div>
            <div>
              <div class="bg-red-500 opacity-[0.5] w-[10px] h-[31px] ml-[7px]"></div>
              <div class="bg-purple-800 opacity-100 w-[10px] h-[20px] ml-[7px]"></div>
              <div class="bg-blue-800 opacity-[0.5] w-[10px] h-[25px] ml-[7px]"></div>
              <div class="bg-pink-500  w-[10px] h-[27px] ml-[7px]"></div>
              <div class="text-white text-[12px] text-center">Tue</div>
            </div>
            <div>
              <div class="bg-red-500 opacity-[0.5] w-[10px] h-[21px] ml-[7px]"></div>
              <div class="bg-purple-800 opacity-100 w-[10px] h-[11px] ml-[7px]"></div>
              <div class="bg-blue-800 opacity-[0.5] w-[10px] h-[14px] ml-[7px]"></div>
              <div class="bg-pink-500  w-[10px] h-[17px] ml-[7px]"></div>
              <div class="text-white text-[12px] text-center">Wed</div>
            </div>
            <div>
              <div class="bg-red-500 opacity-[0.5] w-[10px] h-[31px] ml-[7px]"></div>
              <div class="bg-purple-800 opacity-100 w-[10px] h-[16px] ml-[7px]"></div>
              <div class="bg-blue-800 opacity-[0.5] w-[10px] h-[22px] ml-[7px]"></div>
              <div class="bg-pink-500  w-[10px] h-[27px] ml-[7px]"></div>
              <div class="text-white text-[12px] text-center">Thu</div>
            </div>
            <div>
              <div class="bg-red-500 opacity-[0.5] w-[10px] h-[41px] ml-[7px]"></div>
              <div class="bg-purple-800 opacity-100 w-[10px] h-[16px] ml-[7px]"></div>
              <div class="bg-blue-800 opacity-[0.5] w-[10px] h-[28px] ml-[7px]"></div>
              <div class="bg-pink-500  w-[10px] h-[35px] ml-[7px]"></div>
              <div class="text-white text-[12px] text-center">Fri</div>
            </div>
          </div>
        </div>
      </div>
      <div class="pl-[30px] pr-[20px]">
        <div>
          <RouterLink to="" @click="toggleOpens" class="px-4 m-2 rounded-md text-white">
            <div class="bg-green-900 w-full flex items-center justify-center h-[50px] text-[16px] text-white">+Task
            </div>
          </RouterLink>
          <div v-if="isOpens" class="bg-black w-[320px] h-[700px]] py-[20px] px-[17px] absolute bottom-[25px] left-[276px] z-[1]">
            <div class="text-white mb-[11px]">type name:</div>
            <div class="text-white mb-[10px]">
              <div class="from">
                <div class="form-group">
                  <input type="text" placeholder="Pitch" class="text-white bg-transparent placeholder-white">
                </div>
              </div>
            </div>
            <div class="text-white mb-[8px]">pick group</div>
            <div class="form mb-[10px]">
              <div class="form-group">
                <div class="flex items-center">
                  <input type="radio" id="check" name="gender"
                         class="appearance-none w-4 h-4 rounded-full border-2 border-gray-300 checked:bg-white checked:outline-white checked:border-2 checked:ring-black">
                  <label for="check" class="ml-2 text-white text-[14px]">Mudslangers o Techynaf</label>
                </div>
              </div>
              <div class="form-group">
                <input type="radio" id="check" name="gender"
                       class="appearance-none w-4 h-4 rounded-full border-2 border-gray-300 checked:bg-white checked:border-2">
                <label for="check" class="ml-2 text-white text-[14px]">AnnexCloud o Personal </label>
              </div>
            </div>
            <div class="text-white mb-[10px]">when?</div>
            <div class="flex items-center gap-[50px]">
              <div class="my-[15px]">
                <div class="flex items-center gap-[30px]">
                  <div><img src="../assets/images/arrow-modal-top.png" alt=""></div>
                  <div><img src="../assets/images/arrow-modal-top.png" alt=""></div>
                </div>
                <div class="text-white my-[5px]">24 February</div>
                <div class="flex items-center gap-[30px]">
                  <div><img src="../assets/images/arrow-modal-bottom.png" alt=""></div>
                  <div><img src="../assets/images/arrow-modal-bottom.png" alt=""></div>
                </div>
              </div>
              <div class="form">
                <div class="form-group">
                  <div class="flex items-center">
                    <input type="radio" id="three-check"
                           class="appearance-none w-4 h-4 rounded-full border-2 border-gray-300 checked:bg-white checked:border-transparent focus:outline-none">
                    <label for="three-check" class="ml-2 text-white text-[12px]">Strictly maintain time</label>
                  </div>
                </div>
              </div>
            </div>
            <div class="flex items-center gap-[50px] my-[10px]">
              <div class="text-white text-[12px]">Duration:</div>
              <div class="text-white text-[12px] flex items-center gap-[10px]">16 Hours
                <div
                    class="bg-white w-[100px] h-[3px] relative before:absolute before:bg-white before:w-[12px] before:h-[12px] before:rounded-[10px] before:top-[-4px] before:right-[8px]"></div>
              </div>
            </div>
            <div class="flex items-center gap-[50px]">
              <div class="text-white text-[12px]">Importance:</div>
              <div class="text-white text-[12px] flex items-center gap-[10px] ml-[12px]">Max
                <div
                    class="bg-white w-[100px] h-[3px] relative before:absolute before:bg-white before:w-[12px] before:h-[12px] before:rounded-[10px] before:top-[-4px] before:right-[45px]"></div>
              </div>
            </div>
            <div class="form mb-[10px]">
              <div class="form-group mt-[15px] mb-[10px]">
                <div class="flex items-center">
                  <input type="radio" id="check" name="gender"
                         class="appearance-none w-4 h-4 rounded-full border-2 border-gray-300 checked:bg-white checked:border-transparent focus:outline-none">
                  <label for="check" class="ml-2 text-white text-[12px]">Keep this private</label>
                </div>
              </div>
              <div class="form-group mb-[10px]">
                <div class="flex items-center">
                  <input type="radio" id="check" name="gender"
                         class="appearance-none w-4 h-4 rounded-full border-2 border-gray-300 checked:bg-white checked:border-transparent focus:outline-none">
                  <label for="check" class="ml-2 text-white text-[12px]">This is secret</label>
                </div>
              </div>
              <div class="form-group mb-[40px]">
                <div class="flex items-center">
                  <input type="radio" id="check" name="gender"
                         class="appearance-none w-4 h-4 rounded-full border-2 border-gray-300 checked:bg-white checked:border-transparent focus:outline-none">
                  <label for="check" class="ml-2 text-white text-[12px]">Earlier the Better</label>
                </div>
              </div>
            </div>
            <div class="flex items-center gap-[10px]">
              <RouterLink to="">
                <div
                    class="bg-red-300 opacity-[0.5] w-[140px] flex items-center justify-center h-[40px] text-[12px] text-gray-600">
                  Additional Info
                </div>
              </RouterLink>
              <RouterLink to="">
                <div
                    class="bg-red-300 opacity-[0.5] w-[140px] flex items-center justify-center h-[40px] text-[12px] text-gray-600">
                  Create
                </div>
              </RouterLink>
            </div>
          </div>
        </div>
      </div>
    </div>


    <div class="w-full bg-gray-800 pt-[12px] relative">
      <div class="">
        <RouterLink to="" @click="toggleMenu" v-if="!isMenuOpen"><img src="../assets/images/right-arrow.png" alt="img" class="absolute left-[25px]">
        </RouterLink>
        <RouterLink to="" @click="toggleMenuOpens" v-if="!isMenuOpens"><img src="../assets/images/left-arrow.png" alt="img" class="absolute right-[25px]">
        </RouterLink>
      </div>
      <div class="text-center text-white">{{ formattedDate }}</div>
      <div class="flex items-center gap-[25px] mt-[15px] justify-center pl-[40px]">
        <div class="text-white text-[12px]">Sep</div>
        <div class="text-white text-[12px]">Oct</div>
        <div class="text-white text-[12px]">Nov</div>
        <div class="text-white text-[12px]">Dec</div>
        <div class="text-white text-[12px]">Jan</div>
        <div class="flex items-center text-white text-[16px] gap-[30px]"><img src="../assets/images/left-arrow.png"
                                                                              alt="img">
          February 2024<img src="../assets/images/right-arrow.png" alt=""></div>
        <div class="text-white text-[12px]">Mar</div>
        <div class="text-white text-[12px]">Apr</div>
        <div class="text-white text-[12px]">May</div>
        <div class="text-white text-[12px]">Jun</div>
        <div class="text-white text-[12px]">Jul</div>
        <div class="text-white text-[12px]">Aug</div>
      </div>
      <div class="px-[12px] flex justify-center items-center pt-[20px]">
        <div class="pr-[10px] pt-[30px]">
          <div class="text-gray-600 mb-[12px]">12am</div>
          <div class="text-gray-600 mb-[12px]">1am</div>
          <div class="text-gray-600 mb-[12px]">2am</div>
          <div class="text-gray-600 mb-[12px]">3am</div>
          <div class="text-gray-600 mb-[12px]">4am</div>
          <div class="text-gray-600 mb-[12px]">5am</div>
          <div class="text-gray-600 mb-[12px]">6am</div>
          <div class="text-gray-600 mb-[12px]">7am</div>
          <div class="text-gray-600 mb-[12px]">8am</div>
          <div class="text-gray-600 mb-[12px]">9am</div>
          <div class="text-gray-600 mb-[12px]">10am</div>
          <div class="text-gray-600 mb-[12px]">11am</div>
          <div class="text-gray-600 mb-[12px]">12am</div>
          <div class="text-gray-600 mb-[12px]">1pm</div>
          <div class="text-gray-600 mb-[12px]">2pm</div>
          <div class="text-gray-600 mb-[12px]">3pm</div>
          <div class="text-gray-600 mb-[12px]">4pm</div>
          <div class="text-gray-600 mb-[12px]">5pm</div>
          <div class="text-gray-600 mb-[12px]">6pm</div>
          <div class="text-gray-600 mb-[12px]">7pm</div>
          <div class="text-gray-600 mb-[12px]">8pm</div>
          <div class="text-gray-600 mb-[12px]">9pm</div>
          <div class="text-gray-600 mb-[12px]">10pm</div>
          <div class="text-gray-600 mb-[12px]">11pm</div>
          <div class="text-gray-600">12pm</div>
        </div>
        <div>
          <!-- Days of Week Starts -->
          <table style="border-collapse: separate;border-spacing: 5px;">
            <thead class="mb-[10px] bg-gray-800">
            <tr>
              <th v-for="day in weekDays" :key="day" class="text-white font-[500] text-[12px] w-[500px]">{{ day }}</th>
            </tr>
            </thead>
          </table>
          <!-- Days of Week Ends -->
          
          <!-- Week Schedule Ends -->
          <table style="border-collapse: separate;border-spacing: 5px; background-color: #292d32 ">
            <tbody>
              
            <tr>
              <!-- Sun Starts -->
              <td v-for="day in weekDays" :key="day" class="bg-black h-[912px] w-[500px]">
                <TimeBlock 
                  :block-content="dynamicContent" 
                  :block-class="customClass"
                  :background-color="dynamicColor"
                  :opacity="dynamicOpacity"
                />
                <TriangleMarker />
                <TimeBlock 
                  :block-content="dynamicContent" 
                  :block-class="customClass"
                  :background-color="dynamicColor"
                  :opacity="dynamicOpacity"
                />
                <TimeBlock 
                  :block-content="dynamicContent" 
                  :block-class="customClass"
                  :background-color="dynamicColor"
                  :opacity="dynamicOpacity"
                />
              </td>
            </tr>
            </tbody>
          </table>
          <!-- Week Schedule Ends -->
        </div>
      </div>
    </div>


    <!-- Right Panel Starts -->
    <div v-if="isMenuOpens" class="bg-gray-900 max-w-[500px] py-[14px] h-full relative">
      <div @click="closeMenuopens" class="pb-[30px]">
        <RouterLink to=""><img src="../assets/images/right-arrow.png" alt="img" class="absolute left-[15px]">
        </RouterLink>
      </div>
      <div class="pl-[30px] pr-[20px]">
        <RouterLink to="">
          <div class="bg-green-900 w-full flex items-center justify-center h-[50px] text-[16px] text-white">
            Sign Out
          </div>
        </RouterLink>
      </div>
      <div class="mt-[30px]">
        <div class="flex items-center justify-between px-[5px] mb-[10px]">
          <div><img src="../assets/images/left-arrow-calender.png" alt="img" class="w-[15px]"></div>
          <div class="text-white text-[16px]">February 2024</div>
          <div><img src="../assets/images/right-arrow-calender.png" alt="img" class="w-[15px]"></div>
        </div>
        <table class="mx-[5px]">
          <thead>
          <tr>
            <th class="px-[11px] py-[5px] text-gray-500">Mo</th>
            <th class="px-[11px] py-[5px] text-gray-500">Tu</th>
            <th class="px-[11px] py-[5px] text-gray-500">We</th>
            <th class="px-[11px] py-[5px] text-gray-500">Th</th>
            <th class="px-[11px] py-[5px] text-gray-500">Fr</th>
            <th class="px-[11px] py-[5px] text-gray-500">Sa</th>
            <th class="px-[11px] py-[5px] text-gray-500">Su</th>
          </tr>
          </thead>
          <tbody>
          <tr>
            <td class="px-[11px] py-[5px] text-white">29</td>
            <td class="px-[11px] py-[5px] text-white">30</td>
            <td class="px-[11px] py-[5px] text-white">31</td>
            <td class="px-[11px] py-[5px] text-white">1</td>
            <td class="px-[11px] py-[5px] text-white">2</td>
            <td class="px-[11px] py-[5px] text-white">3</td>
            <td class="px-[11px] py-[5px] text-white">4</td>
          </tr>
          <tr>
            <td class="px-[11px] py-[5px] text-white">5</td>
            <td class="px-[11px] py-[5px] text-white">6</td>
            <td class="px-[11px] py-[5px] text-white">7</td>
            <td class="px-[11px] py-[5px] text-white">8</td>
            <td class="px-[11px] py-[5px] text-white">9</td>
            <td class="px-[11px] py-[5px] text-white">10</td>
            <td class="px-[11px] py-[5px] text-white">11</td>
          </tr>
          <tr style="background: #212425">
            <td class="px-[10px] py-[5px] text-white" style="background: #212425;border-radius: 10px 0 0 10px">12</td>
            <td class="px-[10px] py-[5px] text-white" style="background: #212425;border-radius: 0 0 0 0">13</td>
            <td class="px-[10px] py-[5px] text-white" style="background: #212425;border-radius: 0 0 0 0">14</td>
            <td class="px-[10px] py-[5px] text-white" style="background: #212425;border-radius: 0 0 0 0">15</td>
            <td class="px-[10px] py-[5px] text-white" style="background: #212425;border-radius: 0 0 0 0">16</td>
            <td class="px-[10px] py-[5px] text-white" style="background: #212425;">
              <div class="bg-indigo-700 rounded-[10px] px-[10px] py-[5px]">17</div>
            </td>
            <td class="px-[10px] py-[5px] text-white" style="background: #212425;border-radius: 0 10px 10px 0">18</td>
          </tr>
          <tr>
            <td class="px-[11px] py-[5px] text-white">19</td>
            <td class="px-[11px] py-[5px] text-white">20</td>
            <td class="px-[11px] py-[5px] text-white">21</td>
            <td class="px-[11px] py-[5px] text-white">22</td>
            <td class="px-[11px] py-[5px] text-white">23</td>
            <td class="px-[11px] py-[5px] text-white">24</td>
            <td class="px-[11px] py-[5px] text-white">25</td>
          </tr>
          <tr>
            <td class="px-[11px] py-[5px] text-white">26</td>
            <td class="px-[11px] py-[5px] text-white">27</td>
            <td class="px-[11px] py-[5px] text-white">28</td>
            <td class="px-[11px] py-[5px] text-white">29</td>
            <td class="px-[11px] py-[5px] text-gray-500">1</td>
            <td class="px-[11px] py-[5px] text-gray-500">2</td>
            <td class="px-[11px] py-[5px] text-gray-500">3</td>
          </tr>
          <tr>
            <td class="px-[11px] py-[5px] text-gray-500">4</td>
            <td class="px-[11px] py-[5px] text-gray-500">5</td>
            <td class="px-[11px] py-[5px] text-gray-500">6</td>
            <td class="px-[11px] py-[5px] text-gray-500">7</td>
            <td class="px-[11px] py-[5px] text-gray-500">8</td>
            <td class="px-[11px] py-[5px] text-gray-500">9</td>
            <td class="px-[11px] py-[5px] text-gray-500">10</td>
          </tr>
          </tbody>
        </table>
        <div class="form px-[10px] mt-[25px]">
          <div class="form-group flex items-center gap-[4px] border-gray-600 border-2 p-[8px] rounded-[8px]">
            <img src="../assets/images/search.png" alt="" class="w-[16px]">
            <label for="search"></label>
            <input type="text" id="search" placeholder="Search teammates"
                   class="bg-transparent text-white w-full focus:outline-none">
          </div>
        </div>
      </div>
      <div class="custom-scrollbar px-[10px] h-[580px] overflow-y-scroll">
        <div @click="togglebtnOpen" class="flex items-center text-gray-400 my-[20px]">
          <RouterLink to=""><img src="../assets/images/down-arrow.png" alt="img" class="mr-[10px]"></RouterLink>My Calender
          <RouterLink to=""><img src="../assets/images/pencil.png" alt="img" class="ml-36"></RouterLink>
        </div>
        <div v-if="isbtnOpen" class="mb-[20px]">
          <div class="flex items-center gap-[7px] mb-[10px] text-white"><img src="../assets/images/user.png" alt="img" class="bg-purple-600 p-1 w-[20px] rounded-[4px]">
            <RouterLink to=""> judaedprince@gmail.com</RouterLink>
          </div>
          <div class="flex items-center gap-[7px] mb-[10px] text-white"><img src="../assets/images/user.png" alt="img" class="bg-purple-600 p-1 w-[20px] rounded-[4px]">Calender
          </div>
          <div class="flex items-center gap-[7px] mb-[10px] text-white"><img src="../assets/images/user.png" alt="img" class="bg-indigo-600 p-1 w-[20px] rounded-[4px]">
            <RouterLink to=""> jgpworkspace@gmail.com</RouterLink>
          </div>
          <div class="flex items-center gap-[7px] mb-[10px] text-white"><img src="../assets/images/user.png" alt="img" class="bg-pink-600 p-1 w-[20px] rounded-[4px]">Holiday
            in Bangladesh
          </div>
          <div class="flex items-center gap-[7px] mb-[10px] text-white"><img src="../assets/images/user.png" alt="img" class="bg-purple-600 p-1 w-[20px] rounded-[4px]">Holiday
            in United State
          </div>
          <div class="flex items-center gap-[7px] text-white"><img src="../assets/images/user.png" alt="img" class="bg-indigo-600 p-1 w-[20px] rounded-[4px]">Prayer
            Times
          </div>
        </div>
        <div class="px-[10px]">
          <div class="flex items-center text-gray-400 text-[18px] mb-[20px]">
            Account
            <img src="../assets/images/add.png" alt="img" class="ml-48">
          </div>
        </div>
        <div class="mb-[20px]">
          <div class="rounded-[10px]" style="background: #212425">
            <RouterLink to="" class="flex items-center gap-[7px] mb-[15px] text-white rounded-[6px] p-[8px]"><img
                src="../assets/images/down-arrow.png" alt="img"><img
                src="../assets/images/gmail-icon.png" alt="img" class="w-[30px]">judaedprince@gmail.com
            </RouterLink>
          </div>
          <div class="flex items-center gap-[7px] mb-[10px] text-white"><img src="../assets/images/user.png" alt="img"
                                                                             class="bg-purple-600 p-1 w-[20px] rounded-[4px]">
            <RouterLink to="">judaedprince@gmail.com</RouterLink>
          </div>
          <div class="flex items-center gap-[7px] mb-[10px] text-white">
            <div class="bg-gray-500 w-[20px] h-[20px] border-2 border-sky-500 rounded-[6px]"></div>
            Bangladesh
          </div>
          <div class="flex items-center gap-[7px] mb-[10px] text-white">
            <div class="bg-gray-500 w-[20px] h-[20px] border-2 border-pink-400 rounded-[6px]"></div>
            BlockchainEvents.ca
          </div>
          <div class="flex items-center gap-[7px] mb-[10px] text-white"><img src="../assets/images/user.png" alt="img"
                                                                             class="bg-purple-600 p-1 w-[20px] rounded-[4px]">Calender
          </div>
          <div class="flex items-center gap-[7px] mb-[10px] text-white">
            <div class="bg-gray-500 w-[20px] h-[20px] border-2 border-blue-600 rounded-[6px]"></div>
            Canada
          </div>
          <div class="flex items-center gap-[7px] mb-[10px] text-white">
            <div class="bg-gray-500 w-[20px] h-[20px] border-2 border-gray-400 rounded-[6px]"></div>
            Christian Holidays
          </div>
          <div class="flex items-center gap-[7px] mb-[10px] text-white">
            <div class="bg-gray-500 w-[20px] h-[20px] border-2 border-orange-400 rounded-[6px]"></div>
            Deep Work - Community
          </div>
          <div class="flex items-center gap-[7px] mb-[10px] text-white">
            <div class="bg-gray-500 w-[20px] h-[20px] border-2 border-yellow-300 rounded-[6px]"></div>
            Family
          </div>
          <div class="flex items-center gap-[7px] mb-[10px] text-white"><img src="../assets/images/user.png" alt="img"
                                                                             class="bg-indigo-600 p-1 w-[20px] rounded-[4px]">
            <RouterLink to="">jgpworkspace@gmail.com</RouterLink>
          </div>
          <div class="flex items-center gap-[7px] mb-[10px] text-white">
            <div class="bg-gray-500 w-[20px] h-[20px] border-2 border-green-300 rounded-[6px]"></div>
            Hindu Holiday
          </div>
          <div class="flex items-center gap-[7px] mb-[10px] text-white"><img src="../assets/images/user.png" alt="img"
                                                                             class="bg-pink-600 p-1 w-[20px] rounded-[4px]">Holiday
            in Bangladesh
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TimeBlock from '@/components/TimeBlock.vue';
import TriangleMarker from '@/components/TriangleMarker.vue';
{/* <TriangleMarker /> */}

export default ({
  name: 'HomeView',
  components: {
    TimeBlock,
    TriangleMarker,
  },
  data() {
    return {
      isMenuOpen: false,
      isMenuOpens: false,
      isOpens: false,
      isbtnOpen: false,
      formattedDate: '',
      weekDays: [],
      dynamicContent: "Breakfast : 10:00 am -10:30 am",
      dynamicColor: "#777",
      dynamicOpacity: .9 //
    }
  },
  mounted() {
    this.formatToday();
    this.generateWeekDays();
  },
  methods: {
    generateWeekDays() {
      const daysOfWeek = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
      const today = new Date();
      const currentDayOfWeek = today.getDay(); // 0 for Sunday, 1 for Monday, etc.
      const currentDate = today.getDate();
      const currentMonth = today.getMonth();
      const daysInMonth = new Date(today.getFullYear(), currentMonth + 1, 0).getDate(); // Get total days in current month

      // Calculate the date for the start of the current week (Sunday)
      const startDate = currentDate - currentDayOfWeek;

      // Generate the week days
      for (let i = 0; i < 7; i++) {
        let day = startDate + i;
        if (day < 1) {
          // If the calculated day is in the previous month
          day += daysInMonth;
        } else if (day > daysInMonth) {
          // If the calculated day is in the next month
          day -= daysInMonth;
        }
        // Format the day as "DayOfMonth"
        this.weekDays.push(`${daysOfWeek[i]} ${day}`);
      }
    },
    formatToday() {
      const daysOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
      const today = new Date();
      const dayOfWeek = daysOfWeek[today.getDay()];
      const dayOfMonth = today.getDate();
      this.formattedDate = `${dayOfWeek} ${dayOfMonth}`;
    },
    toggleMenu() {
      this.isMenuOpen = !this.isMenuOpen;
    },
    toggleMenuOpens() {
      this.isMenuOpens = !this.isMenuOpens;
    },
    toggleOpens() {
      this.isOpens = !this.isOpens;
    },
    togglebtnOpen() {
      this.isbtnOpen = !this.isbtnOpen;
    },
    closeMenu() {
      this.isMenuOpen = false;
    },
    closeMenuopens() {
      this.isMenuOpens = false;
    }
  }

});
</script>
<style>
/* Custom scrollbar */
.custom-scrollbar::-webkit-scrollbar {
  width: 4px;
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  background-color: rgb(239 68 68);
  border-radius: 4px;
}

.custom-scrollbar::-webkit-scrollbar-thumb:hover {
  background-color: rgba(0, 0, 0, 0.4);
}

</style>