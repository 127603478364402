<template>
    <div class="triangle-marker">
      <div class="marker-base bg-white w-full h-[2px] mt-[-5px] relative translate-y-[-10px]"></div>
      <div class="marker-tip before:absolute before:top-[-14px] before:left-[-10px] before:bg-white before:w-[10px] before:h-[10px] before:rounded-[10px]"></div>
    </div>
  </template>
  
  <script>
  export default {
    name: 'TriangleMarker',
    props: {
      // Add any necessary props here, e.g.,
      // color: { type: String, default: 'white' },
    },
  };
  </script>
  
  <style scoped>
  .triangle-marker {
    position: relative;
  }
  
  .marker-base,
  .marker-tip {
    display: block;
  }
  
  .marker-base {
    /* Customize base styles as needed */
  }
  
  .marker-tip {
    /* Customize tip styles as needed */
  }
  </style>
  